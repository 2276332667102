<template>
  <div class='card'>
    <h1>Проекты</h1>
    <DataView :value='projects' :layout='layout'>
      <template #grid="slotProps">
        <div style="padding: .5em" class="col-6 full-size">
          <Panel :header='slotProps.data.mnemonic'>
            <img :src='slotProps.data.logoMain' :alt='slotProps.data.mnemonic'>
            <div class='onpicture'>
              <h3><b>{{ slotProps.data.name }}</b></h3>
              Создан: {{getDisplayTime(slotProps.data.createdAt)}}
            </div>
            <p> <span v-html='slotProps.data.description'></span> </p>
          </Panel>
        </div>
      </template>
    </DataView>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'Projects',
  data(){
    return{
      projects: [],
      layout: 'grid',
    }
  },
  mounted() {
    this.getTokens();
  },
  methods:{
    async getTokens()
    {
      try
      {
        const resp = await this.axios.get('/api/au_tokens');
        const allTokens = resp.data;
        this.projects.length = 0;
        for (const item of allTokens)
        {
          if (item.isEnabled && item.type === 'asset') this.projects.push(item);
        }
      }
      catch (e)
      {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'api/au_owners get error',
          group: 'info',
          life: 3000,
        });
      }
    },
    getDisplayTime(value)
    {
      return moment(value).format('DD.MM.YYYY hh:mm:ss');
    }
  },
}
</script>

<style scoped>
.onpicture{
  position: relative;
  left: 10px;
  bottom: 100px;
  color: lightgrey;
}
.card {
  background: #ffffff;
  padding: 2rem;
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
  border-radius: 4px;
  margin-bottom: 2rem;
}
.full-size {
  display: inline-block;
}
</style>